import React, { useState, useCallback } from "react";
import {
  Grid,
  Button,
  IconButton,
  Drawer,
  Tabs,
  Tab,
  Box,
  TextField,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  MenuItem,
} from "@mui/material";
import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  Translate as TranslateIcon,
  Flag as FlagIcon,
  Visibility,
  VisibilityOff,
  Email as EmailIcon,
  Lock as LockIcon,
  SupportAgent as SupportAgentIcon,
} from "@mui/icons-material";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { domain } from "../Components/config";
import PuzzleSlider from "../Components/PuzzleComponent";
import Mobile from "../Components/Mobile";
const countryCodes = [{ code: "+91", country: "India" }];

const Login = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPuzzle, setShowPuzzle] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");

  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setOpenDrawer(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${domain}/login`, {
        mobile,
        password,
      });
      if (response.status === 200) {
        const isAdmin = response.data.user.accountType === "Admin";
        login(response.data.token, isAdmin);
        navigate("/home");
        // setShowPuzzle(true);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        alert(error.response.data.msg);
      } else {
        alert("An error occurred while logging in. Please try again");
      }
    }
  };

  const handlePuzzleSolved = useCallback(() => {
    setShowPuzzle(false);
    navigate("/");
  }, [navigate]);

  const handleRegister = () => {
    navigate("/register");
  };

  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <Mobile>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          background: "linear-gradient(90deg, #3BAF4C 0%, #00D08A 100%)",
          padding: "8px 16px",
          color: "black",
        }}
      >
        <Grid item xs={4} textAlign="left">
          <IconButton sx={{ color: "white" }} onClick={handleRedirect}>
            <ArrowBackIosNewIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <img
            src="assets/king99-logo1.png"
            alt="logo"
            style={{ width: "100px", height: "50px" }}
          />
        </Grid>
        <Grid item xs={4} textAlign="right">
          <IconButton onClick={() => setOpenDrawer(true)} color="inherit">
            <TranslateIcon sx={{ color: "white" }} />
            {selectedLanguage && (
              <>
                {selectedLanguage === "EN" && (
                  <FlagIcon
                    component="span"
                    fontSize="small"
                    sx={{ marginLeft: "4px" }}
                  />
                )}
                {selectedLanguage === "HN" && (
                  <FlagIcon
                    component="span"
                    fontSize="small"
                    sx={{ marginLeft: "4px" }}
                  />
                )}
                <span>{selectedLanguage}</span>
              </>
            )}
          </IconButton>
        </Grid>
      </Grid>

      <Drawer
        anchor="bottom"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Grid
          container
          justifyContent="space-around"
          alignItems="center"
          sx={{ padding: "10px" }}
        >
          <Button onClick={() => handleLanguageSelect("EN")}>
            <ReactCountryFlag countryCode="US" svg />
            EN
          </Button>
          <Button onClick={() => handleLanguageSelect("HN")}>
            <ReactCountryFlag countryCode="IN" svg />
            HN
          </Button>
        </Grid>
      </Drawer>

      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          background: "linear-gradient(90deg, #3BAF4C 0%, #00D08A 100%)",
          padding: "16px",
          color: "white",
          minHeight: "fit-content",
        }}
        direction="column"
      >
        <Typography variant="h5">Login</Typography>
        <Typography variant="subtitle2">
          Please login with your phone number or email
        </Typography>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          backgroundColor: "#f2f2f1",
          padding: "16px",
          color: "white",
          minHeight: "fit-content",
        }}
      >
        <Grid item xs={12} sx={{ marginBottom: "50px" }}>
          <form onSubmit={handleEmailLogin}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#1ab266",
                  width: "50%",
                  textAlign: "center",
                },
              }}
            >
              <Tab
                sx={{ width: "50%" }}
                icon={
                  <SendToMobileIcon
                    style={{
                      color: tabValue === 0 ? "#1ab266" : "grey",
                    }}
                  />
                }
                label="phone number"
                style={{ color: tabValue === 0 ? "#1ab266" : "grey" }}
              />
              <Tab
                sx={{ width: "50%" }}
                icon={
                  <EmailIcon
                    style={{
                      color: tabValue === 1 ? "#1ab266" : "grey",
                    }}
                  />
                }
                label="Email"
                style={{ color: tabValue === 1 ? "#1ab266" : "grey" }}
              />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Box display="flex" alignItems="center" mt={2}>
                <SendToMobileIcon sx={{ color: "#1ab266" }} />
                <FormLabel sx={{ marginLeft: "10px", color: "black" }}>
                  Phone number
                </FormLabel>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <TextField
                  select
                  label=""
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  sx={{
                    width: "125px",
                    backgroundColor: "#ffffff",
                    marginBottom: -1,
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#fff",
                      },
                      "&:hover fieldset": {
                        borderColor: "#fff",
                      },
                      "& fieldset": {
                        borderColor: "#fff",
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#777",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#1ab266",
                    },
                    "& .MuiSelect-icon": {
                      color: "white",
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#f6f6f6", // Background color of the dropdown
                        "& .MuiMenuItem-root": {
                          backgroundColor: "#f6f6f6", // Background color of each menu item
                          "&:hover": {
                            backgroundColor: "#f6f6f6", // Background color on hover
                          },
                        },
                      },
                    },
                  }}
                >
                  {countryCodes.map((item) => (
                    <MenuItem key={item.code} value={item.code}>
                      {item.code} {item.country}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Mobile Number"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  type="tel"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  required
                  sx={{
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ffffff !important",
                      },
                      "&:hover fieldset": {
                        borderColor: "#ffffff !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#ffffff !important",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "black",
                    },
                    "& .MuiInputLabel-root": {
                      color: "black",
                    },
                  }}
                  InputProps={{
                    style: { borderRadius: "10px", color: "black" },
                  }}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                />
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Box display="flex" alignItems="center" mt={2}>
                <EmailIcon sx={{ color: "#1ab266" }} />
                <FormLabel sx={{ marginLeft: "10px", color: "black" }}>
                  Email
                </FormLabel>
              </Box>
              <TextField
                label="Email"
                fullWidth
                variant="outlined"
                margin="normal"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                sx={{
                  backgroundColor: "#ffffff",
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#ffffff !important",
                    },
                    "&:hover fieldset": {
                      borderColor: "#ffffff !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#ffffff !important",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "black",
                  },
                  "& .MuiInputLabel-root": {
                    color: "black",
                  },
                }}
                InputProps={{
                  style: { borderRadius: "10px", color: "black" },
                }}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
            </TabPanel>
            <Box display="flex" alignItems="center" mt={2}>
              <LockIcon sx={{ color: "#1ab266" }} />
              <FormLabel sx={{ color: "black", marginLeft: "10px" }}>
                Password
              </FormLabel>
            </Box>
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              variant="outlined"
              margin="normal"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#ffffff !important",
                  },
                  "&:hover fieldset": {
                    borderColor: "#ffffff !important",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#ffffff !important",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "black",
                },
                "& .MuiInputLabel-root": {
                  color: "black",
                },
              }}
              InputProps={{
                style: { borderRadius: "10px", color: "black" },
                endAdornment: (
                  <IconButton
                    onClick={handleShowPassword}
                    edge="end"
                    sx={{ color: "black" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "2px 0 20px",
              }}
            >
              <RadioGroup row style={{ color: "#1ab266" }}>
                <FormControlLabel
                  value="remember"
                  control={<Radio style={{ color: "#1ab266" }} />}
                  label="Remember Password "
                  labelPlacement="end"
                  style={{ color: "black" }}
                />
              </RadioGroup>
            </Box>
            <Button
              variant="contained"
              fullWidth
              type="submit"
              style={{
                marginBottom: "8px",
                background: "linear-gradient(90deg, #3BAF4C 0%, #00D08A 100%)",
                borderRadius: "300px",
                fontWeight: "bold",
              }}
            >
              Log in
            </Button>
            <Button
              onClick={handleRegister}
              variant="outlined"
              color="primary"
              fullWidth
              style={{
                borderRadius: "300px",
                borderColor: "#1ab266",
              }}
            >
              <span
                style={{
                  color: "#1ab266",
                  marginLeft: "3px",
                  fontWeight: "bold",
                }}
              >
                Register
              </span>
            </Button>
          </form>
        </Grid>
        <Grid
          xs={6}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ padding: "16px", marginTop: "3px", cursor: "pointer" }}
          onClick={() => navigate("/forgot-password")}
        >
          <LockIcon
            style={{
              fontSize: 50,
              color: "#1ab266",
              marginBottom: "10px",
            }}
          />
          <Typography
            variant="subtitle1"
            style={{ color: "black", marginBottom: "150px" }}
          >
            Forgot password
          </Typography>
        </Grid>
        <Grid
          xs={6}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ padding: "16px" }}
        >
          <SupportAgentIcon style={{ fontSize: 60, color: "#1ab266" }} />
          <Typography
            variant="subtitle1"
            style={{ color: "black", marginBottom: "150px" }}
          >
            Customer Service
          </Typography>
        </Grid>
      </Grid>

      <Modal
        open={showPuzzle}
        onClose={() => setShowPuzzle(false)}
        aria-labelledby="puzzle-modal"
        aria-describedby="puzzle-to-complete-login"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            bgcolor: "background.paper",
            boxShadow: 24,
            // p: 4,
            borderRadius: 2,
          }}
        >
          <PuzzleSlider onPuzzleSolved={handlePuzzleSolved} />
        </Box>
      </Modal>
    </Mobile>
  );
};

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
};

export default Login;
