import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  Alert,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { domain } from '../../Components/config';

function DummyUserMain() {
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [dummyUsers, setDummyUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true); // New loading state for users

  // Fetch dummy users
  const fetchDummyUsers = async () => {
    setLoadingUsers(true);
    try {
      const response = await axios.get(`${domain}/dummyusers`, { withCredentials: true });
      if (response.data.success) {
        setDummyUsers(response.data.dummyUsers);
      }
    } catch (error) {
      console.error('Error fetching dummy users:', error);
      setErrorMessage('Failed to fetch dummy users.');
    } finally {
      setLoadingUsers(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await axios.post(`${domain}/registerdummyuser`, { mobile, password }, { withCredentials: true });
      if (response.data.success) {
        setSuccessMessage('Dummy user created successfully!');
        setMobile('');
        setPassword('');
        fetchDummyUsers(); // Refresh the list of dummy users
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.msg);
      } else {
        setErrorMessage('An error occurred while creating the user.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Fetch dummy users when component mounts
  useEffect(() => {
    fetchDummyUsers();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80vh',
        bgcolor: '#f5f5f5',
        padding: '20px',
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          maxWidth: '400px',
          bgcolor: 'white',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom textAlign="center">
          Create Dummy User
        </Typography>

        <TextField
          label="Mobile Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          required
        />

        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: '#0f6518', // Change button color
            '&:hover': {
              backgroundColor: '#0d5615', // Darker shade on hover
            },
            marginTop: '20px',
            padding: '10px',
          }}
          fullWidth
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Create Dummy User'}
        </Button>

        {/* Success and Error Messages */}
        {successMessage && (
          <Alert severity="success" sx={{ marginTop: '20px' }}>
            {successMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert severity="error" sx={{ marginTop: '20px' }}>
            {errorMessage}
          </Alert>
        )}
      </Box>

      {/* Table for displaying dummy users */}
      <Box
        sx={{
          marginTop: '40px',
          width: '100%',
          maxWidth: '800px',
          bgcolor: 'white',
          borderRadius: '10px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h5" component="h2" textAlign="center" sx={{ padding: '10px' }}>
          Dummy Users
        </Typography>

        {loadingUsers ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Account Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dummyUsers.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>{user.mobile}</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.accountType}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
}

export default DummyUserMain;
