import React, { useState, useEffect } from "react"
import "./MainPage.css"
import uidimg from "../../assets/uidimg.png"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import SettingBottomBox from "./settingBottom"
import lockimg from "../../assets/lock.svg"
import mailBox from "../../assets/mail.svg"
import googleVerification from "../../assets/googleValidation.png"
import update from "../../assets/versionUpdate.svg"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import modalimg from "../../assets/person.png"
import axios from "axios"
import Alert from "@mui/material/Alert"
import { domain } from "../config"
import { useNavigate } from "react-router-dom"

function MainPage() {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [openResetPassword, setOpenResetPassword] = useState(false)

  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [alertOpen, setAlertOpen] = useState(false)

  const [userData, setUserData] = useState(null)

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${domain}/user`, {
          withCredentials: true,
        })
        console.log("Data-->",response.data)
        setUserData(response.data)
      } catch (err) {
        console.error(err)
      }
    }

    fetchUserData()
  }, [])
  const handleOpenResetPassword = () => {
    setOpenResetPassword(true)
  }

  const handleCloseResetPassword = () => {
    setOpenResetPassword(false)
  }
  const handleResetPassword = async (event) => {
    event.preventDefault()

    try {
      const response = await axios.post(
        " https://dragonclubs.online/ChangePassword",
        {
          oldPassword,
          newPassword,
        },
        { withCredentials: true }
      )

      if (response.status === 200) {
        setAlertOpen(true)
        handleCloseResetPassword()
      }
    } catch (err) {
      console.error(err)
    }
  }

  const [user, setUser] = useState(null)
  const [username, setUsername] = useState(null)
  const [openChangeUsername, setOpenChangeUsername] = useState(false)

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${domain}/user`, {
        withCredentials: true,
      })
      setUser(response.data.user)
      console.log(response.data.user)
      setUsername(response.data.user.username)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  const handleOpenChangeUsername = () => {
    setOpenChangeUsername(true)
  }

  const handleCloseChangeUsername = () => {
    setOpenChangeUsername(false)
  }

  const handleChangeUsername = async (event) => {
    event.preventDefault()

    try {
      const response = await axios.put(
        `${domain}/user/username`,
        {
          username,
        },
        { withCredentials: true }
      )

      if (response.status === 200) {
        setUserData({ ...userData, username }) // update the username in the local state
        handleCloseChangeUsername()
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleAvatarChange = () => {
    navigate("/avatar-change", { state: { avatar: user.avatar } });
  };

  return (
    <div className="settingpage-main-container">
      <div className="settingpage-top">
        <div className="settingpage-info">
          <div className="avatar">
            <div className="avatar-image">
              <img src={user?.avatar} alt="" />
            </div>
            <div className="change-avatar">
              <span>Change Avatar</span>
              <KeyboardArrowRightIcon onClick={handleAvatarChange} />
            </div>
          </div>
          <div className="settingpage-name">
            <h4>Nickname</h4>
            <div className="name">
              <Button
                sx={{ color: "rgb(99, 99, 99)" }}
                onClick={handleOpenChangeUsername}
              >
                <span>{user ? username : "Loading..."}</span>
                <KeyboardArrowRightIcon />
              </Button>
            </div>

            <Dialog
              open={openResetPassword}
              onClose={handleCloseResetPassword}
              PaperProps={{
                component: "form",
                onSubmit: handleResetPassword,
                sx: {
                  backgroundColor: "#ffffff",
                  color: "black",
                },
              }}
            >
              <DialogTitle sx={{ color: "black" }}>Reset Password</DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ color: "black" }}>
                  Enter your old password and new password.
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="oldPassword"
                  name="oldPassword"
                  label="Old Password"
                  type="password"
                  fullWidth
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  sx={{
                    "& .MuiInputBase-input": { color: "black" },
                    "& .MuiInputLabel-root": { color: "black" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#0F6518", // Default outline color
                      },
                      "&:hover fieldset": {
                        borderColor: "#0F6518", // Outline color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0F6518", // Outline color when focused
                      },
                    },
                  }}
                />
                <TextField
                  required
                  margin="dense"
                  id="newPassword"
                  name="newPassword"
                  label="New Password"
                  type="password"
                  fullWidth
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  sx={{
                    "& .MuiInputBase-input": { color: "black" },
                    "& .MuiInputLabel-root": { color: "black" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#0F6518", // Default outline color
                      },
                      "&:hover fieldset": {
                        borderColor: "#0F6518", // Outline color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0F6518", // Outline color when focused
                      },
                    },
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseResetPassword} sx={{ color: "black" }}>
                  Cancel
                </Button>
                <Button type="submit" sx={{ color: "black" }}>
                  Change Password
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
              <Alert severity="success">Password changed successfully!</Alert>
            </Dialog>
          </div>

          <div className="settingpage-uid">
            <h4>UID</h4>
            <div className="uid">
              <span>{user && user.uid ? user.uid : "Loading..."}</span>
              <ContentCopyIcon sx={{ color: "white" }} />
            </div>
          </div>

        </div>
      </div>
      <div className="bottom-page">
        <div
          className="bottom-heading"
          style={{ textAlign: "left", color: "black" }}
        >
          <h3>Security Information</h3>
        </div>

        <Dialog
          open={openChangeUsername}
          onClose={handleCloseChangeUsername}
          PaperProps={{
            component: "form",
            onSubmit: handleChangeUsername,
            sx: {
              backgroundColor: "#ffffff",
              color: "black",
            },
          }}
        >
          <DialogTitle sx={{ color: "black" }}>Change Username</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: "black" }}>
              Enter your new username.
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="username"
              name="username"
              label="New Username"
              type="text"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{
                "& .MuiInputBase-input": { color: "rgb(102,100,98)" },
                "& .MuiInputLabel-root": { color: "rgb(102,100,98)" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0F6518", // Default outline color
                  },
                  "&:hover fieldset": {
                    borderColor: "#0F6518", // Outline color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0F6518", // Outline color when focused
                  },
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseChangeUsername} sx={{ color: "black" }}>
              Cancel
            </Button>
            <Button type="submit" sx={{ color: "black" }}>
              Change Username
            </Button>
          </DialogActions>
        </Dialog>

        <div className="bottom-box-container">
          <SettingBottomBox
            settingBottomImage={lockimg}
            bottomBoxName="Login Password"
            bottomGoto="Edit"
            onClick={handleOpenResetPassword}
          />

          <SettingBottomBox
            settingBottomImage={mailBox}
            bottomBoxName="Bind Mailbox"
            bottomGoto="Edit"
          />

          {/* <SettingBottomBox
                        settingBottomImage={googleVerification}
                        bottomBoxName='Google Verification'
                        bottomGoto='Edit' /> */}

          <SettingBottomBox
            settingBottomImage={update}
            bottomBoxName="Updated Version"
            bottomGoto="1.0.1"
          />
        </div>
      </div>
    </div>
  )
}

export default MainPage