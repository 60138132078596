// **************LocalHost******************

// export const domain = 'http://localhost:4008';
// export const wssdomain = 'ws://localhost:4008';


// **************Production******************

// export const domain = 'https://daminigames.com';
// export const wssdomain = 'wss://daminigames.com';

// **************Production2******************

// export const domain = 'https://demo2.anotechsolutions.cloud';
// export const wssdomain = 'wss://demo2.anotechsolutions.cloud';

export const domain = 'https://king99.in';
export const wssdomain = 'wss://king99.in';